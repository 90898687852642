import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c(VRow,[_c(VCol,{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('h3',[_vm._v("Menu")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('hr')])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"3","md":"3","sm":"3"}},[_c('label',[_vm._v("Friendly Name")]),_c(VTextField,{attrs:{"rules":[_vm.validations.required],"single-line":"","outlined":"","dense":""},model:{value:(_vm.menuRequest.friendlyName),callback:function ($$v) {_vm.$set(_vm.menuRequest, "friendlyName", $$v)},expression:"menuRequest.friendlyName"}})],1),_c(VCol,{attrs:{"cols":"10","lg":"3","md":"3","sm":"3"}},[_c('label',[_vm._v("MDI Icon")]),_c(VTextField,{attrs:{"rules":[_vm.validations.required],"single-line":"","prefix":_vm.prefix,"outlined":"","dense":""},on:{"keyup":function($event){return _vm.setMdiIcon()}},model:{value:(_vm.menuRequest.mdiIconWithoutPrefix),callback:function ($$v) {_vm.$set(_vm.menuRequest, "mdiIconWithoutPrefix", $$v)},expression:"menuRequest.mdiIconWithoutPrefix"}})],1),_c(VCol,{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"1","lg":"1","md":"1","sm":"1"}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(_vm.menuRequest.mdiIcon))]),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":"https://materialdesignicons.com/","target":"_blank"}},[_c(VChip,_vm._g({staticClass:"ma-2",staticStyle:{"border":"none","font-size":"30px !important"},attrs:{"label":"","link":"","outlined":""}},on),[_vm._v(" ... ")])],1)]}}])},[_c('span',[_vm._v("To Go Site - Material Designer")])])],1),_c(VCol,{attrs:{"cols":"12","lg":"1","md":"1","sm":"1"}},[_c('label',[_vm._v("Order")]),_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"rules":[_vm.validations.required],"single-line":"","outlined":"","dense":""},model:{value:(_vm.menuRequest.order),callback:function ($$v) {_vm.$set(_vm.menuRequest, "order", $$v)},expression:"menuRequest.order"}})],1)],1),_c('ActionButtons',{attrs:{"request":_vm.menuRequest,"loading":_vm.loading,"showButtons":{
                cancel: true,
                save: true
            }},on:{"cancel":_vm.cancel,"save":_vm.save}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }